import { mutations } from "@/constants/state";
import { map, orderBy, reject } from "lodash";

export default {
  [mutations.HOLIDAYS_FETCH_APPROVED_MUTATION]: (state, payload) => {
    state.approvedHolidays = payload;
  },
  [mutations.HOLIDAYS_FETCH_UNAPPROVED_MUTATION]: (state, payload) => {
    state.unapprovedHolidays = payload;
  },
  [mutations.HOLIDAYS_FETCH_CONFIRMED_MUTATION]: (state, payload) => {
    state.confirmedHolidays = payload;
  },
  [mutations.HOLIDAYS_FETCH_USER_HOLIDAYS_MUTATION]: (state, payload) => {
    state.userHolidays = payload;
  },
  [mutations.HOLIDAYS_CREATE_USER_HOLIDAY_MUTATION]: (state, payload) => {
    state.userHolidays = orderBy(
      [...state.userHolidays, payload],
      "until",
      "desc"
    );
  },
  [mutations.HOLIDAYS_UPDATE_USER_HOLIDAY_MUTATION]: (state, payload) => {
    const data = map(state.userHolidays, (holidays) =>
      holidays.id === payload.id ? { ...holidays, ...payload } : holidays
    );

    state.userHolidays = orderBy(data, "until", "desc");
  },
  [mutations.HOLIDAYS_REMOVE_UNAPPROVED_HOLIDAY_MUTATION]: (state, payload) => {
    state.unapprovedHolidays = reject(state.unapprovedHolidays, {
      id: payload,
    });
  },
  [mutations.HOLIDAYS_REMOVE_CONFIRMED_HOLIDAY_MUTATION]: (state, payload) => {
    state.confirmedHolidays = reject(state.confirmedHolidays, { id: payload });
  },
  [mutations.HOLIDAYS_REMOVE_USER_HOLIDAY_MUTATION]: (state, payload) => {
    state.userHolidays = reject(state.userHolidays, { id: payload });
  },
};
