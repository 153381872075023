import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const getApprovedHolidays = (from, to, jobFilterId) => {
  return axios.get(`${API_ENDPOINT()}/holidays/approved`, {
    params: { from, to, jobFilterId },
  });
};

export const getUnapprovedHolidays = () => {
  return axios.get(`${API_ENDPOINT()}/holidays/unapproved`);
};

export const getConfirmedHolidays = (payload) => {
  return axios.get(`${API_ENDPOINT()}/holidays/confirmed`, {
    params: payload,
  });
};

export const getUserHolidays = (userId) => {
  return axios.get(`${API_ENDPOINT()}/holidays/getby/user/${userId}`);
};

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/holidays`, payload);
};

export const update = (id, payload) => {
  return axios.put(`${API_ENDPOINT()}/holidays/${id}`, payload);
};

export const updateApproved = (id, approved) => {
  return axios.post(
    `${API_ENDPOINT()}/holidays/setApproved/${id}?_method=PUT`,
    { approved }
  );
};

export const remove = (id, sendMail) => {
  return axios.delete(`${API_ENDPOINT()}/holidays/${id}`, {
    params: { sendMail },
  });
};
